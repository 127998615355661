@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '_variables';

$theme-colors:map-merge($theme-colors, (
  'primary': $theme-accent,
  'secondary': $theme-primary,
));

$modal-backdrop-bg: darken(cornflowerblue, 25%);
$modal-backdrop-opacity: 0.3;

$headings-color: $theme-primary;
$headings-font-weight: bold;

$container-max-widths:map-merge($container-max-widths, (
  sm: 540px,
  md: 720px,
  lg: 1920px,
  xl: 1920px,
  xxl: 1920px,
));

$border-radius: 0.5rem;
$btn-border-radius: $border-radius;

$btn-font-weight: 600;

@import '~bootstrap/scss/bootstrap';

.btn {
  text-transform: uppercase;
}

.btn-primary {
  @include button-variant(
    $background: $theme-accent,
    $border: $theme-accent,
    $color: #fff,
    $hover-color: #fff,
    $active-color: #fff
  );
}

.btn-outline-primary {
  @include button-variant(
    $background: white,
    $border: $theme-accent,
    $color: $theme-accent,
    $hover-background: shade-color($theme-accent, $btn-hover-bg-shade-amount),
    $hover-border: shade-color($theme-accent, $btn-hover-border-shade-amount),
    $hover-color: #fff,
    $active-background: shade-color($theme-accent, $btn-hover-bg-shade-amount),
    $active-color: #fff
  );
}
