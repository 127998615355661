@import '_bootstrap';

body {
  font-family: 'Poppins', sans-serif;
}

input:not(.is-invalid),
select:not(.is-invalid) {
  border: 1px solid transparent;
}

input.is-invalid,
select.is-invalid {
  padding-right: 0 !important;
}

input, select {
  box-shadow: 0 0 10px #00000020;
  color: var(--bs-body-color) !important;
}
:root {
  --bs-body-color: rgba(0, 0, 0, 0.5);
}

div#stepwizard-nav-back-container {
  display: none !important;
}